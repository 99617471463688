<template>
	<b-card no-body class="mb-0 mt-2">
		<!-- <b-card-header class="pb-1">
			<b-card-title></b-card-title>
			<span v-show="lastUpdate != null"
				>Last Update: {{ lastUpdate }}</span
			>
		</b-card-header> -->

		<b-card-body id="root" class="w-100 h-100 p-1">
			<simple-loading
				:is-loading="isLoading"
				:is-load-failed="loadFail"
				@reload="onReload"
			>
				<div class="chart-container">
					<number-attention-summary :items="items" />
				</div>
			</simple-loading>
		</b-card-body>
	</b-card>
</template>

<script>
	import { BCard, BCardBody, BCardTitle, BCardHeader } from "bootstrap-vue";
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
	import { computed, ref, onMounted } from "@vue/composition-api";
	import NumberAttentionSummary from "../../number-attention/NumberAttentionSummary.vue";
	import store from "@/store";
	import apis from "@configs/apis";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { getLocalDateTimeString } from '@/utils/date-time-helper';

	export default {
		components: {
			NumberAttentionSummary,

			BCardHeader,
			BCardTitle,
			BCard,
			BCardBody,
			SimpleLoading,
		},
		setup(props, ctx) {
			const isLoading = ref(true);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const items = ref([]);

			async function fetchChart() {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const queryParams =
						store.getters["fulfillment/GET_QUERY_PARAMS"];

					const summaryRes = await fetcher(
						apis.outboundHandledPerDayChart,
						FetchMethod.GET,
						queryParams
					);

					items.value = [
						{
							title: "Average PCs Per Order",
							value: summaryRes.avgUnitsPerOrder,
						},
						{
							title: "Orders Handled Per Day",
							value: summaryRes.ordersHandledPerDay,
						},
						{
							title: "PCs Handled Per Day",
							value: summaryRes.unitsHandledPerDay,
						},
						{
							title: "Parcels Handled Per Day",
							value: summaryRes.parcelsHandledPerDay,
						},
					];
					
					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			function onReload() {
				fetchChart();
			}

			onMounted(() => {
				fetchChart();
			})

			return {
				isLoading,
				loadFail,
				lastUpdate,
				items,
				onReload,
			};
		},
	};
</script>

<style scoped>
</style>