<template>
  <div id="root">
    <div id="value">
      {{ formatNumber(value) }}
    </div>
    <div id="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import {} from '@vue/composition-api'
import { formatNumber } from '@/utils/ui-helper';

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      formatNumber
    }
  },
}
</script>

<style scoped>
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#value {
  font-size: 40px;
  color: orange;
  font-weight: bold;
}
#tilte {
}
</style>
