<template>
	<el-table :data="items" style="width: 100%" size="mini">
		<template v-for="tableHeader in tableHeaders">
			<el-table-column
				:fixed="tableHeader.prop == 'date'"
				min-width="50px"
				:width="tableHeader.prop != 'date' ? null : '165px'"
				:align="tableHeader.prop != 'date' ? 'right' : 'left'"
				:header-align="tableHeader.prop != 'date' ? 'right' : 'left'"
				:prop="tableHeader.prop"
				:label="tableHeader.label"
				:key="tableHeader.prop"
			>
			</el-table-column>
		</template>
	</el-table>
</template>

<script>
	import { computed } from "@vue/composition-api";
	import { BTable } from "bootstrap-vue";
	import store from "@/store";
	import { getZonedDatesFromFullDates } from "@/utils/date-time-helper";
	import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
	import { formatNumber } from "@/utils/ui-helper";
	import dayjs from 'dayjs';

	export default {
		components: {
			BTable,
		},
		props: {
			data: {
				type: Object,
				required: true,
			},
		},
		setup(props, ctx) {
			const formatDate = (date) => {
				if (store.getters["fulfillment/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_DATE) {
					return dayjs(date).format('DD');
				} else if (store.getters["fulfillment/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_MONTH) {
					return dayjs(date).format('MMM');
				} else {
					return dayjs(date).format('YYYY');
				}
			}

			const tableHeaders = computed(() => {
				let firstDay = store.getters["fulfillment/GET_QUERY_PARAMS"].currentStartDate;

				let dateLabel;
				if (store.getters["fulfillment/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_DATE) {
					dateLabel = "Date (" + dayjs(firstDay).year() + ")";
				} else if (store.getters["fulfillment/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_MONTH) {
					dateLabel = "Month (" + dayjs(firstDay).year() + ")";
				} else {
					dateLabel = "Year";
				}

				return [
					{ prop: "date", label: dateLabel },
					...Object.keys(props.data).map((date) => {
						return { prop: formatDate(date), label: formatDate(date) };
					}),
				];
			});

			const items = computed(() => {
				let result = [
					{ date: "Number of order" },
					{ date: "Number of unit" },
					{ date: "Average unit per order" },
				];

				Object.keys(props.data).forEach((date) => {
					let key = formatDate(date);

					result[0][key] = formatNumber(
						Math.round(props.data[date].outboundOrderCount)
					);
					result[1][key] = formatNumber(
						Math.round(props.data[date].outboundPiece)
					);
					result[2][key] = formatNumber(
						Math.round(props.data[date].avgUnitPerOrder)
					);

					if (result[0][key] == 0) result[0][key] = "-";
					if (result[1][key] == 0) result[1][key] = "-";
					if (result[2][key] == 0) result[2][key] = "-";
				});

				return result;
			});

			return {
				tableHeaders,
				items,
			};
		},
	};
</script>

<style scoped>
	#type-cell {
		width: 170px;
		font-weight: bold;
	}

	#other-header {
		text-align: right !important;
	}

	#other-cell {
		width: 80px;
		text-align: right;
	}
</style>