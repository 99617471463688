<template>
  <div class="container-fluid">
    <div class="row">
      <div
        v-for="(item, idx) in items"
        :key="idx"
        :class="cellClass"
      >
        <number-attention
          :value="item.value"
          :title="item.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import NumberAttention from './NumberAttention.vue'

export default {
  components: {
    NumberAttention,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const cellClass = computed(() => {
      let { length } = props.items
      if (length >= 5) {
        length = 4
      }
      const cellSize = 12 / length
      return `col-${cellSize}`
    })

    return {
      cellClass,
    }
  },
}
</script>

<style scoped>
</style>
