<template>
	<div id="root" ref="element">
		<fulfillment-filter />

		<data-card-list />
<!-- :style="'height: ' + (size.height - 170) + 'px'" -->
		<div style="flex: 1;">
			<VueSlickCarousel v-bind="settings" class="remaining-area pt-1">
				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-12 p-1 h-100">
							<outbound-unit-order-chart-panel />
						</div>
					</div>
				</div>

				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-12 p-1 h-100">
							<outbound-order-piece-parcel-panel />
						</div>
					</div>
				</div>

				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-6 h-50 p-1">
							<InboundUnit />
						</div>

						<div class="col-6 h-50 p-1">
							<OutboundUnit />
						</div>

						<div class="col-6 h-50 p-1">
							<OutboundOrder />
						</div>

						<div class="col-6 h-50 p-1">
							<OutboundAvgUnitPerOrder />
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";
	import { computed, onMounted, onUnmounted, ref } from "@vue/composition-api";
	import store from "@/store";
	import sizeHook from "@common-components/sizeHook";
	import FulfillmentFilter from "./components/FulfillmentFilter.vue";
	import DataCardList from "./components/data-cards/operation/DataCardList.vue";
	import OutboundUnitOrderChartPanel from "./components/charts/operation/outbound-unit-order/OutboundUnitOrderChartPanel.vue";
	import OutboundOrderPieceParcelPanel from "./components/charts/operation/outbound-order-piece-parcel/OutboundOrderPieceParcelPanel.vue";

	import InboundUnit from "./components/charts/operation/InboundUnit.vue";
	import OutboundUnit from "./components/charts/operation/OutboundUnit.vue";
	import OutboundOrder from "./components/charts/operation/OutboundOrder.vue";
	import OutboundAvgUnitPerOrder from "./components/charts/operation/OutboundAvgUnitPerOrder.vue";

	export default {
		components: {
			VueSlickCarousel,

			FulfillmentFilter,
			DataCardList,

			OutboundUnitOrderChartPanel,
			OutboundOrderPieceParcelPanel,

			InboundUnit,
			OutboundUnit,
			OutboundOrder,
			OutboundAvgUnitPerOrder,
		},
		setup() {
			const [size, element] = sizeHook();

			return {
				settings: {
					lazyLoad: "ondemand",
					arrows: true,
					dots: true,
					centerMode: true,
					centerPadding: "50px",
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 1,
					speed: 500,
				},

				size,
				element
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.remaining-area {
		flex: 1;
	}
	.padding-chart {
		padding: 0.5rem;
	}
</style>
