<template>
	<div id="root">
		<div style="flex: 1;">
			<!-- <div class="container-fluid h-100">
				<div class="row h-100">
					<div class="col-6">
						<outbound-order-piece-chart />
					</div>

					<div class="col-6">
						<outbound-parcel-chart />
					</div>
				</div>
			</div> -->
			<outbound-order-piece-chart />
		</div>

		<outbound-unit-order-summary/>
	</div>
</template>

<script>
	import OutboundOrderPieceChart from "./OutboundOrderPieceChart.vue";
	import OutboundParcelChart from "./OutboundParcelChart.vue";
	import OutboundUnitOrderSummary from "../OutboundUnitOrderSummary.vue";
	import Chart from "@common-components/chart/Chart.vue";
	export default {
		components: {
			Chart,

			OutboundOrderPieceChart,
			OutboundParcelChart,
			OutboundUnitOrderSummary,
		},
	};
</script>

<style scoped>
#root {
	display: flex;
	flex-direction: column;
}
</style>