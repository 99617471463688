<template>
	<chart
		supportPieDetail
		title="Outbound Order"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@click="onClick"
		@reload="onReload"
	/>
</template>

<script>
	import { chartAmountFormatter } from '@/utils/ui-helper';
	import apis from "@configs/apis";
	import useLineLineBarChart from '../../../../components/chart/useLineLineBarChart';
	import Chart from "@common-components/chart/Chart.vue";

	export default {
		components: {
			Chart,
		},
		setup(props, ctx) {
			return useLineLineBarChart({ 
				detailApi: apis.outboundOrderClientChart,
				detailTitle: "Outbound Order",


				queryParams: "fulfillment/GET_QUERY_PARAMS", 
				chartApi: apis.outboundOrderChart, 
				customFormatter: chartAmountFormatter, 
				yAxisLabel: "HKD",
				yAxisLabel2: "Accumulate"
			});
		},
	};
</script>
