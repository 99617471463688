<template>
	<data-card
		:customClass="'mb-0'"
		:background-color="backgroundColor"
		:title="title"
		:type="type"
		:is-loading="isLoading"
		:value="wrapperValue"
		postfix=""
	/>
</template>

<script>
	import DataCard from "@common-components/data-card/DataCard.vue";
	import DataCardTypes from "@common-components/data-card/data-card-types";
	import Colors from "@configs/colors";
	import { ref, onMounted, computed, watch } from "@vue/composition-api";
	import { RANGE_CUSTOM_MONTH, RANGE_LAST_MONTH } from "@configs/filter";
	import store from "@/store";

	export default {
		components: {
			DataCard,
		},
		props: {
			isLoading: {
				type: Boolean,
				required: true,
			},
			loadFail: {
				type: Boolean,
				required: true,
			},
			lastUpdate: {
				type: String,
			},
			value: {
				type: Object,
			},
			onReload: {
				type: Function,
			},
		},
		setup(props) {
			const wrapperValue = computed(() => {
				if (props.value == null) return "0 / 0";
				return props.value.fteCount + " / " + props.value.pteCount;
			});

			const baseTitle = "FTE / PTE";
			const title = ref(baseTitle);
			const setTitle = () => {
				if (
					store.getters["fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH"]
						.range == RANGE_LAST_MONTH
				) {
					title.value = baseTitle + " (Last Month)";
				} else {
					title.value = baseTitle;
				}
			};

			watch(
				() => store.getters["fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH"],
				function () {
					setTitle();
				}
			);

			onMounted(() => {
				setTitle();
			});

			return {
				backgroundColor: Colors.Teal,
				title,
				type: DataCardTypes.String,
				wrapperValue,
			};
		},
	};
</script>

<style scoped>
</style>