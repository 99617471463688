<template>
	<div id="root">
		<outbound-unit-order-chart />

		<outbound-unit-order-summary />
	</div>
</template>

<script>
	import OutboundUnitOrderChart from "./OutboundUnitOrderChart.vue";
	import OutboundUnitOrderSummary from "../OutboundUnitOrderSummary.vue";

	export default {
		components: {
			OutboundUnitOrderChart,
			OutboundUnitOrderSummary,
		},
	};
</script>

<style scoped>
	#root {
		/* display: flex;
		flex-direction: column; */
	}
</style>