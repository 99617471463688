<template>
	<VueSlickCarousel v-bind="settings">
		<div class="gap h-100" v-for="component in components" :key="component">
			<component :is="component" :isLoading="isLoading" :loadFail="loadFail" :lastUpdate="lastUpdate" :value="value" :onReload="onReload"  />
		</div>
	</VueSlickCarousel>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";

	import InboundUnits from "./InboundUnits.vue";
	import OutboundUnits from "./OutboundUnits.vue";
	import OutboundOrders from "./OutboundOrders.vue";
	import VASUnits from "./VASUnits.vue";
	import FTEOrPTE from "./FTEOrPTE.vue";
	import FTEOrPTERatio from "./FTEOrPTERatio.vue";

	import useDataCard from "../useDataCard";
	import apis from "@configs/apis";

	export default {
		components: {
			VueSlickCarousel,

			InboundUnits,
			OutboundUnits,
			OutboundOrders,
			VASUnits,
			FTEOrPTE,
			FTEOrPTERatio,
		},
		setup() {
			const { isLoading, loadFail, lastUpdate, value, onReload } = useDataCard(apis.fulfillmentOperationSummary, "fulfillment/GET_QUERY_PARAMS_WITH_LAST_MONTH");

			return {
				isLoading,
				loadFail,
				lastUpdate,
				value,
				onReload,

				components: [
					"InboundUnits",
					"OutboundUnits",
					"OutboundOrders",
					"VASUnits",
					"FTEOrPTE",
					"FTEOrPTERatio",
				],
				settings: {
					arrows: false,
					dots: false,
					centerMode: true,
					centerPadding: "0px",
					autoplay: true,
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 6,
					speed: 500,

					responsive: [
						{
							breakpoint: 1400,
							settings: {
								slidesToShow: 5,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 1300,
							settings: {
								slidesToShow: 4,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 900,
							settings: {
								slidesToShow: 3,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 700,
							settings: {
								slidesToShow: 2,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 590,
							settings: {
								slidesToShow: 1,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
					],
				},
			};
		},
	};
</script>

<style scoped>
	.gap {
		padding-left: 10px;
		padding-right: 10px;
	}
</style>