<template>
	<chart
		title="Outbound Order vs Parcel"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	/>
</template>

<script>
	import { chartAmountFormatter } from "@/utils/ui-helper";
	import apis from "@configs/apis";
	import useLineBarChart from "../../../../../components/chart/useLineBarChart";
	import Chart from "@common-components/chart/Chart.vue";
	import store from "@/store";

	export default {
		components: {
			Chart,
		},
		setup() {
			return useLineBarChart({
				detailApi: apis.inboundVolumeClientChart,
				detailTitle: "Outbound Order EA",

				queryParams: "fulfillment/GET_QUERY_PARAMS",
				chartApi: apis.outboundFeeOrderEA,
				customLegend: (legend) => {
					return ["Outbound Orders", "Outbound Parcels"];
				},
				customSeries: (series) => {
					series[0].name = "Outbound Orders";

					series[1].name = "Outbound Parcels";
					series[1].yAxisIndex = 1;
					return series;
				},
				customYAxis: (yAxis) => {
					return [
						{ ...yAxis[0], name: "Order Count" },
						{ ...yAxis[0], name: "Outbound PCs" },
					];
				},
			});
		},
	};
</script>
