<template>
	<chart
		title="Outbound Unit Order Summary"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
	>
		<template slot="after-chart">
			<div style="margin-top: 20px">
				<outbound-unit-order-table :data="chartData"/>
			</div>
		</template>
	</chart>
</template>

<script>
	import Chart from "@common-components/chart/Chart.vue";
	import {
		getLocalDateTimeString,
		getZonedDatesFromFullDates,
	} from "@/utils/date-time-helper";
	import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
	import { chartNumberFormatter } from "@/utils/ui-helper";
	import store from "@/store";
	import apis from "@configs/apis";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { ref, onMounted, watch, computed } from "@vue/composition-api";
	import OutboundUnitOrderTable from "./OutboundUnitOrderTable.vue";

	export default {
		components: {
			Chart,
			OutboundUnitOrderTable,
		},
		setup(props, ctx) {
			const isLoading = ref(true);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
            const options = ref({});
			const chartData = ref(null);

			onMounted(() => {
				fetchChart();
			})

			watch(
				() => store.getters["fulfillment/GET_QUERY_PARAMS"],
				function () {
					fetchChart();
				}
			)

			function onReload() {
				fetchChart();
			}

			async function fetchChart() {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const queryParams = store.getters["fulfillment/GET_QUERY_PARAMS"];

					const chartRes = await fetcher(
						apis.outboundUnitsOrdersChart,
						FetchMethod.GET,
						queryParams
					);
					setOptions(chartRes);
					chartData.value = chartRes;

					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

        
			function setOptions(data) {
				let xAxisData;
				if (store.getters["fulfillment/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_DATE) {
					xAxisData = getZonedDatesFromFullDates(
						Object.keys(data)
					);
				} else if (store.getters["fulfillment/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_MONTH) {
					xAxisData = Object.keys(data);
				} else {
					xAxisData = Object.keys(data);
				}

				options.value = {
					legend: {
						data: [
							"Number of order",
							"Number of unit",
							"Average unit per order",
						],
					},
					grid: {
						top: "50px",
						right: "60px",
					},
					xAxis: {
						type: "category",
						data: xAxisData,
						axisPointer: {
							type: "shadow",
						},
					},
					yAxis: [
						{
							type: "value",
							axisLabel: {
								formatter: chartNumberFormatter,
							},
						},
						{
							type: "value",
							axisLabel: {
								formatter: chartNumberFormatter,
							},
						},
					],
					series: [
						{
							name: "Number of order",
							data: Object.values(data).map((val) =>
								Math.round(val.outboundOrderCount)
							),
							type: "bar",
						},
						{
							name: "Number of unit",
							data: Object.values(data).map((val) =>
								Math.round(val.outboundPiece)
							),
							type: "bar",
						},
						{
							name: "Average unit per order",
							yAxisIndex: 1,
							data: Object.values(data).map((val) =>
								Math.round(val.avgUnitPerOrder)
							),
							type: "line",
						},
					],
				};
			}

	
			return {
                isLoading,
                loadFail,
                lastUpdate,
				options,
				onReload,
				chartData,
            }
		},
	};
</script>
